import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { io } from 'socket.io-client';
import { get_api_request } from '../../helpers/Common.js';
import Cookies from 'js-cookie';
const { decrypt } = require('../../helpers/encryption-decryption');
import { headers } from '../../helpers/variables';

const socket = io.connect('https://socket.sentseen.com/');

const Dashboard = lazy(() => import('../../container/dashboard'));
const Ecommerce = lazy(() => import('../../container/dashboard/Ecommerce'));
const Business = lazy(() => import('../../container/dashboard/Business'));
const Performance = lazy(() => import('../../container/dashboard/Performance'));
const CRM = lazy(() => import('../../container/dashboard/CRM'));
const AddPins = lazy(() => import('../../container/pages/AddPins'));
// const QuizzesList = lazy(() => import('../../container/pages/QuizzesList'));

//
// const AddPost = lazy(() => import('../../container/pages/AddPost'));
// const EditPost = lazy(() => import('../../container/pages/EditPost'));
// const ViewPost = lazy(() => import('../../container/pages/ViewPost'));

// const AddPins = lazy(() => import('../../container/pages/AddPins'));
const StudentDashboard = lazy(() => import('../../container/dashboard/StudentDashboard'));
const TeacherDashboard = lazy(() => import('../../container/pages/ClientDashboard'));
const StudentHome = lazy(() => import('../../container/dashboard/StudentDashboard'));
const UsersList = lazy(() => import('../../container/pages/UsersList'));
const AddUserRole = lazy(() => import('../../container/pages/AddUsersRole'));
const Roles_Permissions = lazy(() => import('../../container/pages/EditUsersRole'));
const UserGroup = lazy(() => import('../../container/pages/UserGroup'));
const Configuration = lazy(() => import('../../container/pages/Configuration'));
const SocialAccounts = lazy(() => import('../../container/pages/SocialAccounts'));

const GeneralSetting = lazy(() => import('../../container/pages/GeneralSetting'));
const LocalSettings = lazy(() => import('../../container/pages/LocalSettings'));
const HomePageSetting = lazy(() => import('../../container/pages/HomePageSetting'));
const EmailSetting = lazy(() => import('../../container/pages/EmailSetting'));
const PaymentSetting = lazy(() => import('../../container/pages/PaymentSetting'));
const BillandTaxSetting = lazy(() => import('../../container/pages/BillandTaxSetting'));
const ThemeSetting = lazy(() => import('../../container/pages/ThemeSetting'));
const MainteneceSetting = lazy(() => import('../../container/pages/MainteneceSetting'));
const Home = lazy(() => import('../../container/pages/RequestQuote'));
const Client = lazy(() => import('../../container/pages/Client'));
const Leads = lazy(() => import('../../container/pages/Leads'));
const Templates = lazy(() => import('../../container/pages/Templates'));
const SlyBroadCast = lazy(() => import('../../container/pages/SlyBroadCast'));
const Contact = lazy(() => import('../../container/pages/Contact'));
const ElevenLabs = lazy(() => import('../../container/pages/ElevenLabs'));
const Models = lazy(() => import('../../container/pages/Models'));
const ContactHistory = lazy(() => import('../../container/pages/ContactHistory'));
const SettingsVoice = lazy(() => import('../../container/pages/SettingVoice'));
const SMTPSetting = lazy(() => import('../../container/pages/SMTPSetting'));
const Campaign = lazy(() => import('../../container/pages/Campaign'));
const AddCampaign = lazy(() => import('../../container/pages/AddCampaign'));
const Messages = lazy(() => import('../../container/pages/Message.js'));
const Messagelog = lazy(() => import('../../container/pages/Messagelog.js'));
const ApplicationAi = lazy(() => import('../../container/pages/ApplicationAi'));
const EdiApplication = lazy(() => import('../../container/pages/EdiApplication'));
const TriggerLog = lazy(() => import('../../container/pages/TriggerLog'));
const Webhook = lazy(() => import('../../container/pages/Webhook'));
const Gohighlevel = lazy(() => import('../../container/pages/Gohighlevel'));
const StaffPhones = lazy(() => import('../../container/pages/StaffPhones'));
const Pin = lazy(() => import('../../container/pages/Pin'));
const PinSubaccount = lazy(() => import('../../container/pages/PinSubaccount'));

const AddMessageLogs = lazy(() => import('../../container/pages/AddMessageLogs'));
const EditMessageLogs = lazy(() => import('../../container/pages/EditMessageLogs'));
const AddEvents = lazy(() => import('../../container/pages/AddEvents'));
const EditEvents = lazy(() => import('../../container/pages/EditEvents'));

const RoadMapList = lazy(() => import('../../container/pages/RoadMapList'));
const AddRoadMap = lazy(() => import('../../container/pages/AddRoadMap'));
const EditRoadMap = lazy(() => import('../../container/pages/EditRoadMap.js'));

const SmsTemplate = lazy(() => import('../../container/pages/SmsTemplate.js'));

const LeadsAi = lazy(() => import('../../container/pages/LeadsAi'));

const SubscriptionPlans = lazy(() => import('../../container/pages/Subscriptions'));
const AddSubscriptionPlan = lazy(() => import('../../container/pages/AddSubscriptionPlan'));
const EditSubscriptionPlan = lazy(() => import('../../container/pages/EditSubscriptionPlan'));

const SubscribedUser = lazy(() => import('../../container/pages/SubscribedUsers'));
const AddSubscribedUser = lazy(() => import('../../container/pages/AddSubscribedUser'));
const EditSubscribedUser = lazy(() => import('../../container/pages/EditSubscribedUser'));

const PlanType = lazy(() => import('../../container/pages/PlanType'));
const PlanTenure = lazy(() => import('../../container/pages/PlanTenure'));
const Payment = lazy(() => import('../../container/pages/Payment'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    var enc_userDetail = Cookies.get('UserDetail');
    var User_Details = decrypt(enc_userDetail);

    async function GetClientID(clientid) {
      const url = '/clients/' + clientid;
      const response = await get_api_request(url, headers);
      if (response.status == 200) {
        const clientdata = response?.data?.responsedata[0];
        GetAllLeads(clientdata?.apikey);
      } else {
        console.log('error');
      }
    }
    GetClientID(User_Details?.sessdata?.user?.[0]?.client_id);
    async function GetAllLeads(apiKey) {
      const url = '/event/clientleads';
      var header = {};
      header['token'] = apiKey;
      const response = await get_api_request(url, header);
      if (response.status === 200) {
        const leaddata = response?.data?.responsedata;
        leaddata?.forEach(element => {
          socket.emit('join_room', element?.contact_id);
        });
      } else {
        console.log('error');
      }
    }
  }, []);

  return (
    <Switch>
      {/* 1 */}
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/social`} component={Dashboard} />
      <Route exact path={`${path}/eco`} component={Ecommerce} />
      <Route exact path={`${path}/business`} component={Business} />
      <Route exact path={`${path}/performance`} component={Performance} />
      <Route exact path={`${path}/crm`} component={CRM} />
      <Route exact path={`${path}/admin`} component={AddPins} />
      <Route path={`${path}/configuration/users`} component={UsersList} />
      <Route path={`${path}/configuration/add-role`} component={AddUserRole} />
      <Route path={`${path}/configuration/usergroup`} component={UserGroup} />
      <Route path={`${path}/configuration/roles-permissions`} component={Roles_Permissions} />
      {/* Start  Settings */}
      <Route path={`${path}/configuration/general-settings`} component={GeneralSetting} />
      <Route path={`${path}/configuration/local-settings`} component={LocalSettings} />
      <Route path={`${path}/homepage-settings`} component={HomePageSetting} />
      <Route path={`${path}/configuration/email-settings`} component={EmailSetting} />
      <Route path={`${path}/configuration/payment-settings`} component={PaymentSetting} />
      <Route path={`${path}/configuration/billandtax-settings`} component={BillandTaxSetting} />
      <Route path={`${path}/configuration/theme-settings`} component={ThemeSetting} />
      <Route path={`${path}/configuration/maintenance-settings`} component={MainteneceSetting} />
      <Route exact path="/" component={Home} />
      <Route path={`${path}/student`} component={StudentDashboard} />
      <Route path={`${path}/clients`} component={AddPins} />
      <Route path={`${path}/studenthome`} component={StudentHome} />
      <Route path={`${path}/configuration`} component={Configuration} />
      <Route path={`${path}/client`} component={Client} />
      <Route path={`${path}/templates`} component={Templates} />
      <Route path={`${path}/contacts`} component={Contact} />
      <Route path={`${path}/sly_broadcast`} component={SlyBroadCast} />
      <Route path={`${path}/eleven_labs`} component={ElevenLabs} />
      <Route path={`${path}/models`} component={Models} />
      <Route path={`${path}/leadsai`} component={LeadsAi} />
      <Route path={`${path}/gohighlevelplatform`} component={Pin} />
      <Route path={`${path}/gohighlevelplatform_sub`} component={PinSubaccount} />
      <Route path={`${path}/ContactHistory/:id`} component={ContactHistory} />
      <Route path={`${path}/account_settings`} component={SettingsVoice} />
      <Route path={`${path}/smtp-settings`} component={SMTPSetting} />

      <Route path={`${path}/add-campaign`} component={AddCampaign} />
      <Route path={`${path}/campaign`} component={Campaign} />
      <Route path={`${path}/messages`} component={Messages} />
      <Route path={`${path}/application_ai`} component={ApplicationAi} />
      <Route path={`${path}/editapplication/:id`} component={EdiApplication} />
      <Route path={`${path}/trigger_logs`} component={TriggerLog} />
      <Route path={`${path}/webhook_ai`} component={Webhook} />
      <Route path={`${path}/accounts`} component={Gohighlevel} />
      <Route path={`${path}/staff_phones`} component={StaffPhones} />
      <Route path={`${path}/messagelogs`} component={Messagelog} />
      <Route path={`${path}/addmessage_logs`} component={AddMessageLogs} />
      <Route path={`${path}/editmessage_logs/:id`} component={EditMessageLogs} />
      <Route path={`${path}/addEvents`} component={AddEvents} />
      <Route path={`${path}/EditEvents/:id`} component={EditEvents} />
      <Route path={`${path}/automation`} component={RoadMapList} />
      <Route path={`${path}/addRoadmap`} component={AddRoadMap} />
      <Route path={`${path}/EditRoadmap/:id`} component={EditRoadMap} />
      <Route path={`${path}/smstemplate`} component={SmsTemplate} />
      <Route path={`${path}/leads`} component={Leads} />
      <Route exact path={`${path}/googlesheet`} component={SocialAccounts} />
      <Route exact path={`${path}/subscription-plans`} component={SubscriptionPlans} />
      <Route exact path={`${path}/add-subscription-plan`} component={AddSubscriptionPlan} />
      <Route exact path={`${path}/edit-subscription-plan`} component={EditSubscriptionPlan} />
      <Route exact path={`${path}/subscribed_users`} component={SubscribedUser} />
      <Route exact path={`${path}/add-subscribed_users`} component={AddSubscribedUser} />
      <Route exact path={`${path}/edit-subscribed_user`} component={EditSubscribedUser} />
      <Route exact path={`${path}/plan_tenure`} component={PlanTenure} />
      <Route exact path={`${path}/plan_type`} component={PlanType} />
      <Route exact path={`${path}/payments`} component={Payment} />
    </Switch>
  );
};

export default DashboardRoutes;
