import React, { useEffect, useState } from 'react';
import { Menu, Tooltip } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookies from 'js-cookie';
import { get_api_request } from '../helpers/Common';
import { headers } from '../helpers/variables';
const { decrypt } = require('../helpers/encryption-decryption');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const { SubMenu } = Menu;
var UserRole = [];

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [RoleName, setRoleName] = useState();
  const [UserInfo, setUserInfo] = useState();
  const [userRoleName, setUserRoleName] = useState();
  const [ClientData, setClientData] = useState();
  const arrayOfUserId = ['417', '500', '423', '362'];
  useEffect(() => {
    var enc_userDetail = Cookies.get('UserDetail');
    var response = decrypt(enc_userDetail);
    console.log(response);

    async function GetClientId() {
      var id = response?.sessdata?.user[0].client_id;
      const url = '/clients/' + id;
      const responseData = await get_api_request(url, headers);
      if (responseData.status == 200) {
        const clientdata = responseData?.data?.responsedata[0];
        setClientData(clientdata);
      } else {
        console.log('error');
      }
    }
    GetClientId();

    if (response?.login == true) {
      const UserInfo = response?.sessdata?.user?.[0];
      setUserInfo(UserInfo);
      const GetRole = UserInfo?.user_role?.toUpperCase();
      setUserRoleName(GetRole);
      setRoleName(GetRole);
      if (UserInfo?.permissions != 'No Permission for role') {
        const modules = UserInfo?.permissions?.[GetRole].MODULES;
        UserRole = modules;
        console.log(UserRole);
      }
    }
  }, []);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      gutter={30}
      className="custmblock"
    >
      {UserRole != undefined ? (
        <>
          {UserRole['DASHBOARD'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/`}>
                    <FeatherIcon icon="home" />
                  </NavLink>
                )
              }
              key="h_dashboard"
            >
              {RoleName == 'CLIENT' ? (
                <NavLink onClick={toggleCollapsed} to={`${path}/clients`}>
                  Dashboard
                </NavLink>
              ) : (
                <NavLink onClick={toggleCollapsed} to={`${path}/admin`}>
                  Dashboard
                </NavLink>
              )}
            </Menu.Item>
          ) : (
            ''
          )}
          {RoleName == 'CLIENT' ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/contacts`}>
                    <FeatherIcon icon="user" />
                  </NavLink>
                )
              }
              key="contact"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/contacts`}>
                Contacts (Beta)
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/lead-finder`}>
                    <FeatherIcon icon="search" />
                  </NavLink>
                )
              }
              key="campaign"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/lead-finder`}>
                Lead Finder
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['AGENT'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/client`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key="client"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/client`}>
                Clients
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {/* {arrayOfUserId.includes(UserInfo?.id?.toString()) ? ( */}
          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/automation`}>
                    <FeatherIcon icon="map" />
                  </NavLink>
                )
              }
              key="roadmap"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/automation`}>
                Automation
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {/* {UserRole['AGENT'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/smstemplate`}>
                    <FeatherIcon icon="message-circle" />
                  </NavLink>
                )
              }
              key="smstemplate"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/smstemplate`}>
                Sms Templates
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}

          {/* {UserRole['CONNECTION'] != undefined ? (
            <SubMenu key="subscriptions" icon={!topMenu && <FeatherIcon icon="gift" />} title="Subscriptions">
              <Menu.Item key="subscription-plans">
                <NavLink onClick={toggleCollapsed} to={`${path}/subscription-plans`}>
                  Plans
                </NavLink>
              </Menu.Item> */}
          {/* <Menu.Item key="subscription_plan_type">
                <NavLink onClick={toggleCollapsed} to={`${path}/plan_type`}>
                  Plan Type
                </NavLink>
              </Menu.Item>
              <Menu.Item key="subscription_plan_tenure">
                <NavLink onClick={toggleCollapsed} to={`${path}/plan_tenure`}>
                  Plan Tenure
                </NavLink>
              </Menu.Item> */}
          {/* <Menu.Item key="subscribedusers">
                <NavLink onClick={toggleCollapsed} to={`${path}/subscribed_users`}>
                  Users
                </NavLink>
              </Menu.Item>
            </SubMenu>
          ) : (
            ''
          )} */}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/messagelogs`}>
                    <FeatherIcon icon="file-text" />
                  </NavLink>
                )
              }
              key="messagelogs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/messagelogs`}>
                Message Logs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/messages`}>
                    <FeatherIcon icon="message-square" />
                  </NavLink>
                )
              }
              key="messages"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/messages`}>
                Messages (Beta)
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {/* {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/trigger_logs`}>
                    <FeatherIcon icon="crosshair" />
                  </NavLink>
                )
              }
              key="ai_logs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/trigger_logs`}>
                Ai Logs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {/* {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/webhook_ai`}>
                    <FeatherIcon icon="layout" />
                  </NavLink>
                )
              }
              key="webhook_ai"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/webhook_ai`}>
                Webhooks
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}

          {UserRole['CONNECTION'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/gohighlevelplatform`}>
                    <FeatherIcon icon="bar-chart" />
                  </NavLink>
                )
              }
              key="gohighlevelplatform"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/gohighlevelplatform`}>
                Connection
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['CONNECTION'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/payments`}>
                    <FeatherIcon icon="credit-card" />
                  </NavLink>
                )
              }
              key="payments"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/payments`}>
                Payments
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {RoleName == 'CLIENT' && ClientData?.agency_apikey != null ? (
            <>
              {UserRole['MESSAGE LOGS'] != undefined ? (
                <Menu.Item
                  icon={
                    !topMenu && (
                      <NavLink className="menuItem-iocn" to={`${path}/accounts`}>
                        <FeatherIcon icon="users" />
                      </NavLink>
                    )
                  }
                  key="homepage_settings"
                >
                  <NavLink onClick={toggleCollapsed} to={`${path}/accounts`}>
                    Sub Accounts
                  </NavLink>
                </Menu.Item>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/staff_phones`}>
                    <FeatherIcon icon="phone" />
                  </NavLink>
                )
              }
              key="staff_phones"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/staff_phones`}>
                Staff Phones
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/account_settings`}>
                    <FeatherIcon icon="settings" />
                  </NavLink>
                )
              }
              key="settings"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/account_settings`}>
                Settings
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
